<template>
  <div class="relative w-full h-screen">
    <img src="../assets/Logo_Dark.svg" alt="Logo" class="w-32 fgg-center" />
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
.fgg-center {
  @apply absolute;
  @apply top-1/2;
  @apply left-1/2;
  @apply transform;
  @apply -translate-y-1/2;
  @apply -translate-x-1/2;
}
</style>
