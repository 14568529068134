import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.scss";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createI18n } from "vue-i18n";
import de from "./locales/de.json";
import fr from "./locales/fr.json";
import it from "./locales/it.json";
import en from "./locales/en.json";

const i18n = createI18n({
  locale: "ja",
  fallbackLocale: "en",
  messages: {
    de,
    fr,
    it,
    en,
  },
  warnHtmlInMessage: "off",
});

createApp(App).use(store).use(router).use(Toast, {transition: "Vue-Toastification__fade",}).use(i18n).mount("#app");
