<template>
  <div class="application">
    <router-view />
  </div>
</template>

<style lang="scss">
body,
html {
  @apply bg-accent-500;
  @apply text-primary-500;
  @apply h-full;
  @apply w-full;

  #app {
    @apply h-full;
    @apply w-full;

    .application {
      @apply h-full;
      @apply w-full;
    }
  }
}
</style>
